.image-gallery-svg {
    width: 20px!important;
}

.image-gallery-image{
    height: 400px!important;
}

.fullscreen .image-gallery-image{
    height: unset!important;
}

.image-gallery-thumbnail-image {
    height: 70px!important;
}
.off-border{
    background-color : #d4ffe5;
    padding: 4px;
    margin-left: 20px;
    height: 25px;
    margin-top: 5px;
    border-radius: 5px;
}
.details-imgtext{
    margin-top: 30px;
}

.wishbtn{
    margin-top: 10px !important;
    height: 33px !important;
    font-size: 14px !important;
    font-weight: bold !important;
    box-shadow: none !important;
    width: 100% !important; 
    background-color: #fff !important;; 
    color: #0d6efd !important;
}

@media (min-width: 768px){
    .details-imgtext{
        display: none !important;
    }
}
@media(max-width: 768px){
    .details-righttext{
        display: none !important;
    }
    .details-imgtext{
        margin-top: 20px!important;
    }
    .details-righttext1{
        margin-top: 30px !important;
    }
    .details-price{
        margin-top: 5px !important;
    }
}
@media(min-width: 768px) and (max-width: 991px){
    .details-righttext{
        margin-top: 50px;
    }
}

@media (max-width: 420px){
    .buttonStyledel {
        justify-content: center !important;
        align-items: center !important;
        position: fixed !important;
        bottom: 7px !important;
        width: 93% !important;
        z-index: 1000 !important;
      }
}
