.slick-prev{
    display: none !important;
}
.slick-next{
    display: none !important;
}
.arrival-para1{
    font-size: 15px;
    font-weight: 500;
    margin-bottom: unset;
    overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
   -webkit-box-orient: vertical;
}
.arrival-para2{
    font-size: 16px;
    font-weight: bold;
    margin-bottom: unset;
}
.arrival-para3{
    font-size: 12px;
    font-weight: bold;
    margin-bottom: unset;
}
.slick-dots li {
    margin: unset !important;
}
.home-cartbtn{
    width: 100% !important;
    color: #0d6efd !important;
    font-weight: bold !important;
    font-size: 14px !important;
    border: none !important;
    box-shadow: none !important;
}

