  
  .signup-box {
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    margin: 40px auto 50px;
    width: 100%;
    max-width: 500px;
    display: block;
}

.box-inside {
    background: #ffffff;
    padding: 20px;
    z-index: 20;
    box-shadow: 0 0 1px 0 #ccc;
    -webkit-box-shadow: 0 0 1px 0 #ccc;
    -moz-box-shadow: 0 0 1px 0 #ccc;
    border-radius: 10px;
}

.signuppara{
    font-weight: bold;
    font-size: 30px;
    color: #286bc7;
    text-align: center;
}
.shadow12{
    box-shadow: none !important;
}