.profilebox {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    background-color: #ffffff;
  }

  @media(max-width: 767px){
      .my-profilediv{
          margin-top: 30px !important;
      }
  }

.shadow12{
    box-shadow: none !important;
}