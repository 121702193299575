@media(min-width: 768px) and (max-width: 991px){
    .banner-img{
        height: 200px !important;
    }
}
@media(max-width: 767px){
    .banner-img{
        height: 132px !important;
    }
}

@media (max-width: 991px) {
    /* .banner-img{
       margin-top: 70px;
    } */
}