.checkout-div{
    box-shadow: 0px 1px 3px 1px rgba(116, 116, 116, 0.2);
    margin-top: 10px;
    border-radius: 8px;
}
.check-checkoutpara{
    font-size: 14px; 
    font-weight: 500;
}

@media(min-width: 768px){
    .checkout-prodiv{
        margin-left: 15px !important;
    }
}
@media(max-width: 575px){
    .checkout-maindiv{
        margin-top: 70px !important;
    }
}