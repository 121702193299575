@import url(https://cdn.jsdelivr.net/npm/pixeden-stroke-7-icon@1.2.3/pe-icon-7-stroke/dist/pe-icon-7-stroke.min.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header{
    position: fixed;
    width: 100%;
    z-index: 1000;
    margin: 0;
    top: 0;
}
.header1{
    background-color: #286bc7;
    padding: 10px;
    padding-top: 20px;
}
.header2{
    background-color: #FBD202;
    padding: 10px;
    padding-bottom: 3px;
}
.header1-para{
    font-size: 14px;
    color: #fff;
    font-weight: bold;
}
.header-textinput{
    font-size: 14px; 
    width: 100%; 
    box-shadow: none !important; 
    padding-right: 40px
}
/* .profilebox{
    position: absolute;
    right: 160px;
    z-index: 1000;
    padding: 15px;
    padding-bottom: 1px;
    width: 220px;
    margin-top: 50px;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    border-radius: 6px;
    background-color: #ffffff;
    -webkit-box-shadow:  0px 1px 3px 1px rgba(116, 116, 116, 0.2);
    box-shadow:  0px 1px 3px 1px rgba(116, 116, 116, 0.2);
  } */

@media (max-width: 991px){
    .header1{
        display: none;
    }
    .header2{
        padding-bottom: 10px
    }
}
@media(max-width: 385px){
    /* .responsive-cart{
        margin-top: 30px;
        float: left !important;
        margin-right: 10px !important;
    } */
    /* .responsive-cart1{
        margin-right: 100px;
    } */
}

  
  .dataResult {
    width: 100%;
    max-height: 350px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    overflow: hidden;
    overflow-y: auto;
    position: absolute;
  }
  
  .dataResult::-webkit-scrollbar {
    display: none;
  }
  
 .searchItem {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  #clearBtn {
    cursor: pointer;
  }
  
  .home-category{
    width: 200px;
    max-height: 350px;
    background-color: white;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    border: 1px solid #d3d3d3;
    overflow: hidden;
    overflow-y: auto;
    position: absolute;
    z-index: 999;
    border-bottom: 5px solid #286bc7;
  }

  .home-subcategory2{
    width: 200px;
    background-color: white;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */

    overflow: hidden;
    overflow-y: auto;
    position: absolute;
    z-index: 999;
   
    transition: height 0.4s ease;
    -moz-transition: height 0.4s ease;
    -o-transition: height 0.4s ease;
    -ms-transition: height 0.4s ease;
    -webkit-transition: height 0.4s ease;
    height: 0px;
    margin-left: 200px;
  }

  .catopen2{
    width: 200px;
    background-color: white;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    border: 1px solid #d3d3d3;
    overflow: hidden;
    overflow-y: auto;
    position: absolute;
    z-index: 999;
    border-bottom: 5px solid #286bc7;
    transition: height 0.4s ease;
    -moz-transition: height 0.4s ease;
    -o-transition: height 0.4s ease;
    -ms-transition: height 0.4s ease;
    -webkit-transition: height 0.4s ease;
    height: unset!important;
    margin-left: 200px;
  }


  .home-category2{
    width: 200px;
    background-color: white;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    overflow: hidden;
    overflow-y: auto;
    position: absolute;
    z-index: 999;
    transition: height 0.4s ease;
    -moz-transition: height 0.4s ease;
    -o-transition: height 0.4s ease;
    -ms-transition: height 0.4s ease;
    -webkit-transition: height 0.4s ease;
    height: 0px;
  }

  .catopen1{
    width: 200px;
    background-color: white;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    border: 1px solid #d3d3d3;
    overflow: hidden;
    overflow-y: auto;
    position: absolute;
    z-index: 999;
    border-bottom: 5px solid #286bc7;
    transition: height 0.4s ease;
    -moz-transition: height 0.4s ease;
    -o-transition: height 0.4s ease;
    -ms-transition: height 0.4s ease;
    -webkit-transition: height 0.4s ease;
    height: unset!important;
  }

  .cate-list{
      list-style-type: none;
      padding: 0 !important;
  }
  li{
      cursor: pointer;
  }

  @media(max-width: 450px){
      .home-category2{
          position: 'absolute' !important;
          left: 0 !important;
          top: 80px !important;
      }
  }

.footer-li{
    list-style: none;
    font-size: 13px;
    margin-top: 5px;
    color: #6e6e6e;
}
.footer-copyright{
    font-size: 13px;
    color: #6e6e6e; 
    margin-top: 30px;
    margin-bottom: 30px;
  }

  @media (max-width: 767px){
      .footer-copyright{
          text-align: center;
      }
  }
.cart-div2{
    box-shadow: 0px 1px 3px 1px rgba(116, 116, 116, 0.2);
    margin-top: 30px;
    border-radius: 8px;
}
.cart-checkoutpara{
    font-size: 14px; 
    font-weight: 500;
}
.cart-decr{
    box-shadow: 0px 1px 3px 1px rgba(116, 116, 116, 0.2);
    padding: 11px;
    padding-bottom: 2px;
    padding-top: 0px;
    cursor: pointer;
}
.cart-qty{
    display: flex;
    justify-content: center; 
    margin-top: 30px
}

@media(max-width: 767px){
    .cart-qty{
        margin-top: 10px !important;
    }
    
}
@media(min-width: 768px){
    .cart-prodiv{
        margin-left: 15px !important;
    }
}

.bottom-banner{
    width: 100%; 
    height: 240px;
}
@media(min-width: 768px) and (max-width: 991px){
    .bottom-banner{
        height: 150px !important;
    }
}
@media(max-width: 767px){
    .bottom-banner{
        height: 104px !important;
    }
}

.dealday{
    width: 100%; 
    height: 220px;
    margin-top: 20px;
}
@media(max-width: 991px){
    .dealday{
        height: 550px !important;
    }
    .timer{
        margin-top: 30px !important;
    }
}

.see-all{
    border-color: #ffde09 !important;
    box-shadow: none !important;
    font-size: 12px !important;
    height: 35px !important;
    font-weight: bold !important;
}

.timer-div{
    border: 4px solid #fbd202;
    border-radius: 10px;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    padding: 20px;
    padding-bottom: 1px;
    text-align: center;
}

.home-catpara{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 100%;
    font-size: 13px; 
    margin-bottom: unset;
}

@media(max-width: 991px){
    .home-catpara{
        font-size: 12px!important;
    }
    .img-border {
        height: 289px!important;
    }
}

@media(max-width: 575px){
    .newarrival-img-border{
        border: 1px solid #d3d3d3;
        padding: 5px;
        height: 300px!important
      }
}

@media(min-width: 576px) and (max-width: 991px){
    .home-catpara{
        font-size: 12px!important;
    }
    .img-border {
        height: 289px!important;
    }

    .newarrival-img-border{
        border: 1px solid #d3d3d3;
        padding: 5px;
        height: 300px!important
      }
}

.spacereduce {
    padding-right: 5px !important;
    padding-left: 5px !important;
}

.product-name{
    line-height:40px;
    height:40px;
    text-overflow: ellipsis;
    overflow:hidden;
    white-space: pre;
    font-size: 13px; 
    margin-bottom: unset;

}
.catborder{
    border: 2px solid #286bc7;
    padding: 5px;
    padding-top: 0px;
}

.cart-content{
    box-shadow: 0 0 3px rgb(60 72 88 / 15%);
    width: 400px;
    min-height: 200px;
    max-height: 600px;
    position: fixed;
    right: 0px;
    top: 0px;
    background-color: #ffffff;
    /* overflow-y: auto;
    overflow-x: hidden; */
    padding: 15px;
    z-index: 1000 !important;
  }

  .innerdiv{
    overflow-y: auto;
    overflow-x: hidden;
    height: 400px;
  }

@media (max-width: 500px){
    .cart-content{
        width: 100%;
        min-height: 250px !important;
        max-height: 300px !important;
    }
    .innerdiv{
        overflow-y: auto;
        overflow-x: hidden;
        height: 100px;
      }
}
@media(min-width: 768px) and (max-width: 991px){
    .banner-img{
        height: 200px !important;
    }
}
@media(max-width: 767px){
    .banner-img{
        height: 132px !important;
    }
}

@media (max-width: 991px) {
    /* .banner-img{
       margin-top: 70px;
    } */
}
.slick-prev:before, .slick-next:before {
    color: #000!important;
}

.slick-slide  div {
    outline: unset!important;
    cursor: pointer;
  }

  .slick-arrow{
      position: absolute !important;
      top:  145px !important;
  }
  @media (min-width: 601px) and (max-width: 767px){
    .slick-arrow{
        top:  108px !important;
    }
  }
  @media (min-width: 992px) and (max-width: 1024px){
    .slick-arrow{
        top:  200px !important;
    }
  }

  @media (max-width: 575px){
      .product-leftdiv{
          margin-top: 30px !important;
          margin-left: 15px !important;
      }
      .slick-prev {
        position: absolute !important;
        top: -23px !important;
        right: 70px !important;
        left: unset !important;
      }
      .slick-next {
        position: absolute !important;
        top: -23px !important;
        right: 30px !important;
      }
  }
.slick-prev{
    display: none !important;
}
.slick-next{
    display: none !important;
}
.arrival-para1{
    font-size: 15px;
    font-weight: 500;
    margin-bottom: unset;
    overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
   -webkit-box-orient: vertical;
}
.arrival-para2{
    font-size: 16px;
    font-weight: bold;
    margin-bottom: unset;
}
.arrival-para3{
    font-size: 12px;
    font-weight: bold;
    margin-bottom: unset;
}
.slick-dots li {
    margin: unset !important;
}
.home-cartbtn{
    width: 100% !important;
    color: #0d6efd !important;
    font-weight: bold !important;
    font-size: 14px !important;
    border: none !important;
    box-shadow: none !important;
}



  
  .login-box {
      box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
      margin: 20px auto 30px;
      width: 100%;
      max-width: 500px;
      display: block;
  }
  
  .box-inside {
      background: #ffffff;
      padding: 20px;
      z-index: 20;
      box-shadow: 0 0 1px 0 #ccc;
      -webkit-box-shadow: 0 0 1px 0 #ccc;
      -moz-box-shadow: 0 0 1px 0 #ccc;
      border-radius: 10px;
  }

  .loginpara{
      font-weight: bold;
      font-size: 40px;
      color: #286bc7;
      text-align: center;
  }
  .shadow12{
    box-shadow: none !important;
}
.myordersdiv{
    border: 1px solid #d3d3d3;
    padding: 15px;
    padding-bottom: 1px;
    margin-bottom: 20px;
}

@media (max-width: 450px){
    .order-img{
        width: 40% !important;
    }
    .order-paradiv{
        margin-top: 20px;
    }
}

@media(min-width: 451px) and (max-width: 767px){
    .order-img{
        width: 30% !important;
    }
    .order-paradiv{
        margin-top: 20px;
    }
}
.bottom-banner{
    width: 100%; 
    height: 240px;
}
@media(min-width: 768px) and (max-width: 991px){
    .bottom-banner{
        height: 150px !important;
    }
}
@media(max-width: 767px){
    .bottom-banner{
        height: 104px !important;
    }
}
.slick-prev{
    display: none !important;
}
.slick-next{
    display: none !important;
}
.arrival-para1{
    font-size: 15px;
    font-weight: 500;
    margin-bottom: unset;
}
.arrival-para2{
    font-size: 16px;
    font-weight: bold;
    margin-bottom: unset;
}
.arrival-para3{
    font-size: 12px;
    font-weight: bold;
    margin-bottom: unset;
}
.slick-dots li {
    margin: unset !important;
}
.home-cartbtn{
    width: 100% !important;
    color: #0d6efd !important;
    font-weight: bold !important;
    font-size: 14px !important;
    border: none !important;
    box-shadow: none !important;
}
.home-cartbtn-div{
    display: flex;
    justify-content: center;
    position: absolute;
    width: -webkit-fill-available;
    bottom: 10px;
}

@media (max-width: 767px){
    .pro-relapro{
        font-size: 20px !important;
    }
}

@media (max-width: 991px){
    .product-maindiv{
       margin-top: 0px !important;
    }
}

.product-maindiv{
    margin-top: 150px;
 }
.image-gallery-svg {
    width: 20px!important;
}

.image-gallery-image{
    height: 400px!important;
}

.fullscreen .image-gallery-image{
    height: unset!important;
}

.image-gallery-thumbnail-image {
    height: 70px!important;
}
.off-border{
    background-color : #d4ffe5;
    padding: 4px;
    margin-left: 20px;
    height: 25px;
    margin-top: 5px;
    border-radius: 5px;
}
.details-imgtext{
    margin-top: 30px;
}

.wishbtn{
    margin-top: 10px !important;
    height: 33px !important;
    font-size: 14px !important;
    font-weight: bold !important;
    box-shadow: none !important;
    width: 100% !important; 
    background-color: #fff !important;; 
    color: #0d6efd !important;
}

@media (min-width: 768px){
    .details-imgtext{
        display: none !important;
    }
}
@media(max-width: 768px){
    .details-righttext{
        display: none !important;
    }
    .details-imgtext{
        margin-top: 20px!important;
    }
    .details-righttext1{
        margin-top: 30px !important;
    }
    .details-price{
        margin-top: 5px !important;
    }
}
@media(min-width: 768px) and (max-width: 991px){
    .details-righttext{
        margin-top: 50px;
    }
}

@media (max-width: 420px){
    .buttonStyledel {
        justify-content: center !important;
        align-items: center !important;
        position: fixed !important;
        bottom: 7px !important;
        width: 93% !important;
        z-index: 1000 !important;
      }
}

.img-border{
    border: 1px solid #d3d3d3;
    padding: 5px;
    height: 300px
}
.profilebox {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    background-color: #ffffff;
  }

  @media(max-width: 767px){
      .my-profilediv{
          margin-top: 30px !important;
      }
  }

.shadow12{
    box-shadow: none !important;
}
  
  .signup-box {
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    margin: 40px auto 50px;
    width: 100%;
    max-width: 500px;
    display: block;
}

.box-inside {
    background: #ffffff;
    padding: 20px;
    z-index: 20;
    box-shadow: 0 0 1px 0 #ccc;
    -webkit-box-shadow: 0 0 1px 0 #ccc;
    -moz-box-shadow: 0 0 1px 0 #ccc;
    border-radius: 10px;
}

.signuppara{
    font-weight: bold;
    font-size: 30px;
    color: #286bc7;
    text-align: center;
}
.shadow12{
    box-shadow: none !important;
}
.steps .step {
    display: block;
    width: 100%;
    margin-bottom: 35px;
    text-align: center
}

.steps .step .step-icon-wrap {
    display: block;
    position: relative;
    width: 100%;
    height: 80px;
    text-align: center
}

.steps .step .step-icon-wrap::before,
.steps .step .step-icon-wrap::after {
    display: block;
    position: absolute;
    top: 32%;
    width: 50%;
    height: 3px;
    margin-top: -1px;
    background-color: #e1e7ec;
    content: '';
    z-index: 1
}

.steps .step .step-icon-wrap::before {
    left: 0
}

.steps .step .step-icon-wrap::after {
    right: 0
}

.steps .step .step-icon {
    display: inline-block;
    position: relative;
    width: 50px;
    height: 50px;
    border: 1px solid #e1e7ec;
    border-radius: 50%;
    background-color: #f5f5f5;
    color: #374250;
    font-size: 25px;
    line-height: 51px;
    z-index: 5
}

.steps .step .step-title {
    margin-bottom: 0;
    color: #606975;
    font-size: 14px;
    font-weight: 500
}

.steps .step:first-child .step-icon-wrap::before {
    display: none
}

.steps .step:last-child .step-icon-wrap::after {
    display: none
}

.steps .step.completed .step-icon-wrap::before,
.steps .step.completed .step-icon-wrap::after {
    background-color: #286bc7;
}

.steps .step.completed .step-icon {
    border-color: #286bc7;
    background-color: #286bc7;
    color: #fff
}

@media (max-width: 576px) {
    .flex-sm-nowrap .step .step-icon-wrap::before,
    .flex-sm-nowrap .step .step-icon-wrap::after {
        display: none
    }
}

@media (max-width: 768px) {
    .flex-md-nowrap .step .step-icon-wrap::before,
    .flex-md-nowrap .step .step-icon-wrap::after {
        display: none
    }
    .trackdiv-respo{
        margin-top: 20px;
    }
}

@media (max-width: 991px) {
    .flex-lg-nowrap .step .step-icon-wrap::before,
    .flex-lg-nowrap .step .step-icon-wrap::after {
        display: none
    }

    .track-maindiv{
        margin-top: 200px;
    }
}

@media (max-width: 1200px) {
    .flex-xl-nowrap .step .step-icon-wrap::before,
    .flex-xl-nowrap .step .step-icon-wrap::after {
        display: none
    }
}

.bg-faded, .bg-secondary {
    background-color: #d3d3d3 !important;
}

.mytrackdiv{
    border: 1px solid #d3d3d3;
    padding: 15px;
    padding-bottom: 1px;
}
.checkout-div{
    box-shadow: 0px 1px 3px 1px rgba(116, 116, 116, 0.2);
    margin-top: 10px;
    border-radius: 8px;
}
.check-checkoutpara{
    font-size: 14px; 
    font-weight: 500;
}

@media(min-width: 768px){
    .checkout-prodiv{
        margin-left: 15px !important;
    }
}
@media(max-width: 575px){
    .checkout-maindiv{
        margin-top: 70px !important;
    }
}
.mywishlistsdiv{
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    padding: 15px;
    padding-bottom: 15px;
    margin-bottom: 20px;
}
.my-wishlist{
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
}

@media (max-width: 991px){
    .orderImg{
        width: 100px !important;
    }
    .order-details{
        margin-top: 30px !important;
    }
    .order-deliver{
        margin-top: unset !important;
    }
}
.wishlist-rate{
    background-color: #2cd801;
    width: 50px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
}
.btn11{
    width: 50%;
    margin-top: 15px;
    height: 30px;
    padding-top: 2px !important;
    color: #286bc7 !important;
    background-color: #fbd202 !important;
    box-shadow: none !important;
    font-weight: bold;
}
@font-face {
    font-family: 'CustomFontName'!important;
    src: local('CustomFontName'),
         url(/static/media/Poppins-Regular.8081832f.ttf) format('ttf');
   font-display: swap;
}

body{
    font-family: 'CustomFontName' !important;
}

@media (max-width: 575px){
    .imagehome { 
        width: 100%!important;
        height: 180px!important;
    }

    .imagedeals{
        width: 100%;
        padding: 10px; 
        height: 220px
    }
}

.imagehome {
    width: 100%;
    height: 195px;
}

.imagedeals{
    width: 100%;
    padding: 10px; 
    height: 220px
}

.size-border{
    border: 1px solid #959595;
    margin-left: 20px !important;
    padding: 5px;
    height: 33px;
    margin-top: 5px;
    border-radius: 5px;
    min-width: 35px;
    cursor: pointer;
}

.size-border:hover {
    border: 1px solid #3866df!important;
}

.size .active {
    border: 1px solid #ffc107;
    margin-left: 20px;
    padding: 5px;
    height: 33px;
    margin-top: 5px;
    border-radius: 5px;
    min-width: 35px;
    cursor: pointer;
    background-color: #ffc107;
    
}

.size .active .sizeText{
    margin-bottom: unset; 
    color: #0d6efd !important;
    font-size: 15px!important;
    text-align: center
}

.sizeText {
    margin-bottom: unset; 
    color: #959595!important;
    font-size: 15px!important;
    text-align: center
}

@media (max-width: 575px){
    .spclImg .slick-slide img{
        height: 180px!important;
    }
}

.activecolor {
    border: 2px solid #2874f0!important;
    color: #2874f0!important;
    box-shadow: none;
}

.notactive {
    color: #000;
    text-align: center;
    border-radius: 2px;
    border: 2px solid #e0e0e0;
}

.spclImg .slick-slide img{
    height: 185px;
}


@media (max-width: 575px){
   .desktopcategory {
        display: none!important;
   }
   .mobilecategory{
       display: block!important;
   }
}

.desktopcategory {
    display: block;
}

.mobilecategory{
   display: none;
}

.mob-category {
    background: rgb(255, 254, 222);;
    height: 88px;
    overflow: hidden;
    
    box-shadow: 0 0 3px #ccc;
}

.mob-category-menu {
    width: auto;
    overflow-x: auto;
    white-space: nowrap;
    padding: 4px 15px 8px;
    line-height: 10px;
}

.mob-category .cate-list {
    display: inline-block;
    font-size: 13px;
    text-align: center;
    margin-right: 15px;
}

.mob-category .categ-img {
    height: 50px;
    overflow: hidden;
}

.mob-category .cate-list img {
    display: inline-block;
    height: 50px;
}


.categorytxt {
    /* text-align: center;
    margin-top: 10px;
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre; */
    line-height: 40px;
    height: 40px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
    font-size: 13px;
    margin-bottom: unset;
    width: 80px;
  }

  @media (max-width: 575px){
    .mobile-display {
        display: none!important;
    }

    .header2{
        background-color: #FBD202;
    }
    .mobilebannerbtop {
        margin-top: 50px!important;
     }
    
 }

 .mobilebannerbtop {
    margin-top: 140px;
 }


 /* moonstyle */

 .moonmaindiv{
    position: relative;
    background: rgb(235, 235, 235);
    flex: 1 1;
    min-width: 0px;
    padding-bottom: 25px;
 }

 .laQvkU {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.singlediv{
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(25% - 7.5px);
    padding-bottom: 0px;
    margin-top: 20px;
}

@media (max-width: 575px){
    .singlediv{
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(50% - 7.5px);
        padding-bottom: 0px;
        margin-top: 20px;
    }
 }



.imagediv{
    position: relative;
    background-color: transparent;
    transition: background-color 0.25s ease 0s;
    padding-bottom: 50%;
}

.ipLbIS {
    position: absolute;
    inset: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ipLbIS img:not(.zoomImage) {
    display: block;
    max-width: 100%;
}

.carticon{
    width: 50px;
    height: 50px;
}

.cwhUbk{
    width: 100%;
    height: 100%
}



@media (max-width: 575px){
    .carticon{
        width: 30px!important;
        height: 30px!important;
    }
    .profileicon{
        width: 30px!important;
        height: 30px!important;
    }

    .fetchlogo{
        width: 80px!important;
        height: 25px!important;
        margin-top: 4px!important;
    }

    .searchdiv{
        margin-top: unset!important
    }
    .searchbar{
        display: none!important;
    } 
    .desktop-display {
        display: block!important;
    }
    .cartTop {
        margin-top: unset!important;
    }
     
 }
 
 .carticon{
    width: 50px;
    height: 50px;
}


.profileicon{
    width: 50px;
    height: 50px;
    margin-left: 20px;
}

.fetchlogo{
    width: 180px;
    height: 50px;
}

.searchdiv{
    position: 'relative';
    margin-top: 10px
}



.mobsearchdiv {
    position: absolute;
    z-index: 1000;
    width: 100%!important;
    box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 1px 1px rgb(0 0 0 / 5%);
    -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 1px 1px rgb(0 0 0 / 5%);
    transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    right: -100%;
    height: 100%;
    top: 0;
  }
  
  
  .mobsearchdiv1 {
    position: absolute;
    z-index: 1000;
    width: 100%!important;
    box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 1px 1px rgb(0 0 0 / 5%);
    -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 1px 1px rgb(0 0 0 / 5%);
    transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    right: 0%;
    height: 100%;
    top: 0;
  }

  .searchdiv1 {
      height: 100%;
  }

  .desktop-display {
      display: none;
  }

  .cartTop {
    margin-top: 170px;
    margin-bottom: 30px;
  }

   .mobilemenu {
    position: fixed;
    top: 0;
    bottom: 0px;
    width: 80%;
    height: 100%;
    background-color: #fff;
    z-index: 1000;
    left: -100%;
    box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 1px 1px rgb(0 0 0 / 5%)!important;
    -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05)!important;
    -webkit-box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 1px 1px rgb(0 0 0 / 5%)!important;
    transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
  }

  .mobilemenu1 {
    position: fixed;
    top: 0;
    bottom: 0px;
    width: 80%;
    height: 100%;
    background-color:#fff;
    z-index: 1000;
    left: 0%;
    box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 1px 1px rgb(0 0 0 / 5%)!important;
    -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05)!important;
    -webkit-box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 1px 1px rgb(0 0 0 / 5%)!important;
    transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
  }

  .listtop {
      margin-top: 70px;
      list-style: none;
      padding-left: unset!important;
  }

  .listtop li {
    height: 65px;
    font-size: 20px;
    font-weight: 500;
    padding-left: 15px;
    position: relative;
    overflow: hidden;
    color: #979797;
  }
  .menulistic {
    color: #979797;
    margin-right: 12px;
    font-size: 22px;
  }



  /* new category */

  .luxury-experiences__grid-item {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: calc((100% - 27.8px) / 3);
    border-radius: 10px;
    box-shadow: 0 0 27px 0 rgb(0 0 0 / 15%);
    overflow: hidden;
    padding: 3px;
}

.lazy-image {
    opacity: 1;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
}

.luxury-experiences__grid-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.luxury-experiences__grid-item-title {
    text-align: center;
    font-weight: 600;
    color: #fff;
    font-size: 1.4rem;
    line-height: 1.21;
    letter-spacing: 0.84px;
    z-index: 1;
}

.luxury-experiences__grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}


    .luxury-experiences__grid {
        height: 588px;
        flex: 1 1;
    }


.luxury-experiences__grid-item:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.19);
    z-index: 0;
    transition: all 1s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/* .lazy-image:before {
    content: "";
    background-image: url(https://ui-assets-gc.thrillophilia.com/assets/placeholder-6a8d720….jpg);
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    background-size: cover;
    z-index: -1;
    display: block;
} */

.luxury-experiences__grid-item:nth-child(1) {
    height: 41.24%;
}

.luxury-experiences__grid-item:nth-child(2) {
    margin-top: -70px!important;
    height: 29%;
}

.luxury-experiences__grid-item:nth-child(3) {
    height: 43.53%;
}

.luxury-experiences__grid-item:nth-child(4) {
    margin-top: -127px!important;
    height: 26%;
}

.luxury-experiences__grid-item:nth-child(5) {
    margin-top: -202px!important;
    height: 38.09%;
}

.luxury-experiences__grid-item:nth-child(6) {
    margin-top: 10px!important;
    height: 44.38%;
}

.luxury-experiences__grid-item:nth-child(7) {
    margin-top: -136px!important;
    height: 44.36%;
}

.luxury-experiences__grid-item:nth-child(8) {
    margin-top: -245px!important;
    height: 26.36%;
}

.luxury-experiences__grid-item:nth-child(9) {
    margin-top: 10px!important;
    height: 26.36%;
}

.luxury-experiences__grid-item:nth-child(10) {
    margin-top: -10px!important;
    height: 30.36%;
}

.luxury-experiences__grid-item:nth-child(11) {
    margin-top: -115px!important;
    height: 48.36%;
}

.luxury-experiences__grid-item:nth-child(12) {
    margin-top: 10px!important;
    height: 26.36%;
}

@media (min-width: 1024px) {
    .luxury-experiences__grid-item:not(:nth-child(3n-2)) {
        margin-top: 18px;
    }
}

.luxury-experiences__grid-item {
    text-decoration: unset;
}

@media (max-width: 575px){
    .luxury-experiences__grid-item:nth-child(1) {
        height: 41.24%;
    }
    
    .luxury-experiences__grid-item:nth-child(2) {
        margin-top: -44px!important;
        height: 29%;
    }
    
    .luxury-experiences__grid-item:nth-child(3) {
        height: 43.53%;
    }
    
    .luxury-experiences__grid-item:nth-child(4) {
        margin-top: -67px!important;
        height: 26%;
    }
    
    .luxury-experiences__grid-item:nth-child(5) {
        margin-top: -110px!important;
        height: 38.09%;
    }
    
    .luxury-experiences__grid-item:nth-child(6) {
        margin-top: 5px!important;
        height: 44.38%;
    }
    
    .luxury-experiences__grid-item:nth-child(7) {
        margin-top: -80px!important;
        height: 40.36%;
    }
    
    .luxury-experiences__grid-item:nth-child(8) {
        margin-top: -126px!important;
        height: 26.36%;
    }
    
    .luxury-experiences__grid-item:nth-child(9) {
        margin-top: 5px!important;
        height: 26.36%;
    }
    
    .luxury-experiences__grid-item:nth-child(10) {
        margin-top: -30px!important;
        height: 26.36%;
    }
    
    .luxury-experiences__grid-item:nth-child(11) {
        margin-top: -75px!important;
        height: 40.36%;
    }
    
    .luxury-experiences__grid-item:nth-child(12) {
        margin-top: 5px!important;
        height: 26.36%;
    }

    .luxury-experiences__grid-item-title{
        font-size: 14px!important;
    }

    .luxury-experiences__grid {
        height: 334px;
        /* margin-left: 18px; */
        flex: 1 1;
    }

    .gridadjust {
        margin-top: 177px!important;
    }

    .luxury-experiences__grid-item {
        width: calc((100% - 11.8px) / 3);
    }
 }

 .gridadjust {
     margin-top: 350px;
 }

 .img-hover-zoom img {
    transition: transform 2s, -webkit-filter 1.5s ease-in-out;
    transition: transform 2s, filter 1.5s ease-in-out;
    transition: transform 2s, filter 1.5s ease-in-out, -webkit-filter 1.5s ease-in-out;
    transform-origin: center center;
    -webkit-filter: brightness(100%);
            filter: brightness(100%);
  }
  
  /* The Transformation */
  .img-hover-zoom:hover img {
    -webkit-filter: brightness(50%);
            filter: brightness(50%);
    transform: scale(1.1);
  }

  .detailsMargintop{
      margin-top: 170px!important;
  }

  @media (max-width: 575px){
    .detailsMargintop{
        margin-top: 0px!important;
    }
  }

  .newarrival-img-border{
    border: 1px solid #d3d3d3;
    padding: 5px;
    height: 300px
  }



