.bottom-banner{
    width: 100%; 
    height: 240px;
}
@media(min-width: 768px) and (max-width: 991px){
    .bottom-banner{
        height: 150px !important;
    }
}
@media(max-width: 767px){
    .bottom-banner{
        height: 104px !important;
    }
}
.slick-prev{
    display: none !important;
}
.slick-next{
    display: none !important;
}
.arrival-para1{
    font-size: 15px;
    font-weight: 500;
    margin-bottom: unset;
}
.arrival-para2{
    font-size: 16px;
    font-weight: bold;
    margin-bottom: unset;
}
.arrival-para3{
    font-size: 12px;
    font-weight: bold;
    margin-bottom: unset;
}
.slick-dots li {
    margin: unset !important;
}
.home-cartbtn{
    width: 100% !important;
    color: #0d6efd !important;
    font-weight: bold !important;
    font-size: 14px !important;
    border: none !important;
    box-shadow: none !important;
}
.home-cartbtn-div{
    display: flex;
    justify-content: center;
    position: absolute;
    width: -webkit-fill-available;
    bottom: 10px;
}

@media (max-width: 767px){
    .pro-relapro{
        font-size: 20px !important;
    }
}

@media (max-width: 991px){
    .product-maindiv{
       margin-top: 0px !important;
    }
}

.product-maindiv{
    margin-top: 150px;
 }