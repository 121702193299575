
.bottom-banner{
    width: 100%; 
    height: 240px;
}
@media(min-width: 768px) and (max-width: 991px){
    .bottom-banner{
        height: 150px !important;
    }
}
@media(max-width: 767px){
    .bottom-banner{
        height: 104px !important;
    }
}

.dealday{
    width: 100%; 
    height: 220px;
    margin-top: 20px;
}
@media(max-width: 991px){
    .dealday{
        height: 550px !important;
    }
    .timer{
        margin-top: 30px !important;
    }
}

.see-all{
    border-color: #ffde09 !important;
    box-shadow: none !important;
    font-size: 12px !important;
    height: 35px !important;
    font-weight: bold !important;
}

.timer-div{
    border: 4px solid #fbd202;
    border-radius: 10px;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    padding: 20px;
    padding-bottom: 1px;
    text-align: center;
}

.home-catpara{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 100%;
    font-size: 13px; 
    margin-bottom: unset;
}

@media(max-width: 991px){
    .home-catpara{
        font-size: 12px!important;
    }
    .img-border {
        height: 289px!important;
    }
}

@media(max-width: 575px){
    .newarrival-img-border{
        border: 1px solid #d3d3d3;
        padding: 5px;
        height: 300px!important
      }
}

@media(min-width: 576px) and (max-width: 991px){
    .home-catpara{
        font-size: 12px!important;
    }
    .img-border {
        height: 289px!important;
    }

    .newarrival-img-border{
        border: 1px solid #d3d3d3;
        padding: 5px;
        height: 300px!important
      }
}

.spacereduce {
    padding-right: 5px !important;
    padding-left: 5px !important;
}

.product-name{
    line-height:40px;
    height:40px;
    text-overflow: ellipsis;
    overflow:hidden;
    white-space: pre;
    font-size: 13px; 
    margin-bottom: unset;

}
.catborder{
    border: 2px solid #286bc7;
    padding: 5px;
    padding-top: 0px;
}

.cart-content{
    box-shadow: 0 0 3px rgb(60 72 88 / 15%);
    width: 400px;
    min-height: 200px;
    max-height: 600px;
    position: fixed;
    right: 0px;
    top: 0px;
    background-color: #ffffff;
    /* overflow-y: auto;
    overflow-x: hidden; */
    padding: 15px;
    z-index: 1000 !important;
  }

  .innerdiv{
    overflow-y: auto;
    overflow-x: hidden;
    height: 400px;
  }

@media (max-width: 500px){
    .cart-content{
        width: 100%;
        min-height: 250px !important;
        max-height: 300px !important;
    }
    .innerdiv{
        overflow-y: auto;
        overflow-x: hidden;
        height: 100px;
      }
}