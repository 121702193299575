.footer-li{
    list-style: none;
    font-size: 13px;
    margin-top: 5px;
    color: #6e6e6e;
}
.footer-copyright{
    font-size: 13px;
    color: #6e6e6e; 
    margin-top: 30px;
    margin-bottom: 30px;
  }

  @media (max-width: 767px){
      .footer-copyright{
          text-align: center;
      }
  }