.header{
    position: fixed;
    width: 100%;
    z-index: 1000;
    margin: 0;
    top: 0;
}
.header1{
    background-color: #286bc7;
    padding: 10px;
    padding-top: 20px;
}
.header2{
    background-color: #FBD202;
    padding: 10px;
    padding-bottom: 3px;
}
.header1-para{
    font-size: 14px;
    color: #fff;
    font-weight: bold;
}
.header-textinput{
    font-size: 14px; 
    width: 100%; 
    box-shadow: none !important; 
    padding-right: 40px
}
/* .profilebox{
    position: absolute;
    right: 160px;
    z-index: 1000;
    padding: 15px;
    padding-bottom: 1px;
    width: 220px;
    margin-top: 50px;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    border-radius: 6px;
    background-color: #ffffff;
    -webkit-box-shadow:  0px 1px 3px 1px rgba(116, 116, 116, 0.2);
    box-shadow:  0px 1px 3px 1px rgba(116, 116, 116, 0.2);
  } */

@media (max-width: 991px){
    .header1{
        display: none;
    }
    .header2{
        padding-bottom: 10px
    }
}
@media(max-width: 385px){
    /* .responsive-cart{
        margin-top: 30px;
        float: left !important;
        margin-right: 10px !important;
    } */
    /* .responsive-cart1{
        margin-right: 100px;
    } */
}

  
  .dataResult {
    width: 100%;
    max-height: 350px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    overflow: hidden;
    overflow-y: auto;
    position: absolute;
  }
  
  .dataResult::-webkit-scrollbar {
    display: none;
  }
  
 .searchItem {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  #clearBtn {
    cursor: pointer;
  }
  
  .home-category{
    width: 200px;
    max-height: 350px;
    background-color: white;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    border: 1px solid #d3d3d3;
    overflow: hidden;
    overflow-y: auto;
    position: absolute;
    z-index: 999;
    border-bottom: 5px solid #286bc7;
  }

  .home-subcategory2{
    width: 200px;
    background-color: white;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */

    overflow: hidden;
    overflow-y: auto;
    position: absolute;
    z-index: 999;
   
    transition: height 0.4s ease;
    -moz-transition: height 0.4s ease;
    -o-transition: height 0.4s ease;
    -ms-transition: height 0.4s ease;
    -webkit-transition: height 0.4s ease;
    height: 0px;
    margin-left: 200px;
  }

  .catopen2{
    width: 200px;
    background-color: white;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    border: 1px solid #d3d3d3;
    overflow: hidden;
    overflow-y: auto;
    position: absolute;
    z-index: 999;
    border-bottom: 5px solid #286bc7;
    transition: height 0.4s ease;
    -moz-transition: height 0.4s ease;
    -o-transition: height 0.4s ease;
    -ms-transition: height 0.4s ease;
    -webkit-transition: height 0.4s ease;
    height: unset!important;
    margin-left: 200px;
  }


  .home-category2{
    width: 200px;
    background-color: white;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    overflow: hidden;
    overflow-y: auto;
    position: absolute;
    z-index: 999;
    transition: height 0.4s ease;
    -moz-transition: height 0.4s ease;
    -o-transition: height 0.4s ease;
    -ms-transition: height 0.4s ease;
    -webkit-transition: height 0.4s ease;
    height: 0px;
  }

  .catopen1{
    width: 200px;
    background-color: white;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    border: 1px solid #d3d3d3;
    overflow: hidden;
    overflow-y: auto;
    position: absolute;
    z-index: 999;
    border-bottom: 5px solid #286bc7;
    transition: height 0.4s ease;
    -moz-transition: height 0.4s ease;
    -o-transition: height 0.4s ease;
    -ms-transition: height 0.4s ease;
    -webkit-transition: height 0.4s ease;
    height: unset!important;
  }

  .cate-list{
      list-style-type: none;
      padding: 0 !important;
  }
  li{
      cursor: pointer;
  }

  @media(max-width: 450px){
      .home-category2{
          position: 'absolute' !important;
          left: 0 !important;
          top: 80px !important;
      }
  }
