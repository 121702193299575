.cart-div2{
    box-shadow: 0px 1px 3px 1px rgba(116, 116, 116, 0.2);
    margin-top: 30px;
    border-radius: 8px;
}
.cart-checkoutpara{
    font-size: 14px; 
    font-weight: 500;
}
.cart-decr{
    box-shadow: 0px 1px 3px 1px rgba(116, 116, 116, 0.2);
    padding: 11px;
    padding-bottom: 2px;
    padding-top: 0px;
    cursor: pointer;
}
.cart-qty{
    display: flex;
    justify-content: center; 
    margin-top: 30px
}

@media(max-width: 767px){
    .cart-qty{
        margin-top: 10px !important;
    }
    
}
@media(min-width: 768px){
    .cart-prodiv{
        margin-left: 15px !important;
    }
}