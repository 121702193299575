@import "~react-image-gallery/styles/css/image-gallery.css";
@font-face {
    font-family: 'CustomFontName'!important;
    src: local('CustomFontName'),
         url('./Assets/Fonts/Poppins-Regular.ttf') format('ttf');
   font-display: swap;
}

body{
    font-family: 'CustomFontName' !important;
}

@media (max-width: 575px){
    .imagehome { 
        width: 100%!important;
        height: 180px!important;
    }

    .imagedeals{
        width: 100%;
        padding: 10px; 
        height: 220px
    }
}

.imagehome {
    width: 100%;
    height: 195px;
}

.imagedeals{
    width: 100%;
    padding: 10px; 
    height: 220px
}

.size-border{
    border: 1px solid #959595;
    margin-left: 20px !important;
    padding: 5px;
    height: 33px;
    margin-top: 5px;
    border-radius: 5px;
    min-width: 35px;
    cursor: pointer;
}

.size-border:hover {
    border: 1px solid #3866df!important;
}

.size .active {
    border: 1px solid #ffc107;
    margin-left: 20px;
    padding: 5px;
    height: 33px;
    margin-top: 5px;
    border-radius: 5px;
    min-width: 35px;
    cursor: pointer;
    background-color: #ffc107;
    
}

.size .active .sizeText{
    margin-bottom: unset; 
    color: #0d6efd !important;
    font-size: 15px!important;
    text-align: center
}

.sizeText {
    margin-bottom: unset; 
    color: #959595!important;
    font-size: 15px!important;
    text-align: center
}

@media (max-width: 575px){
    .spclImg .slick-slide img{
        height: 180px!important;
    }
}

.activecolor {
    border: 2px solid #2874f0!important;
    color: #2874f0!important;
    box-shadow: none;
}

.notactive {
    color: #000;
    text-align: center;
    border-radius: 2px;
    border: 2px solid #e0e0e0;
}

.spclImg .slick-slide img{
    height: 185px;
}


@media (max-width: 575px){
   .desktopcategory {
        display: none!important;
   }
   .mobilecategory{
       display: block!important;
   }
}

.desktopcategory {
    display: block;
}

.mobilecategory{
   display: none;
}

.mob-category {
    background: rgb(255, 254, 222);;
    height: 88px;
    overflow: hidden;
    
    box-shadow: 0 0 3px #ccc;
}

.mob-category-menu {
    width: auto;
    overflow-x: auto;
    white-space: nowrap;
    padding: 4px 15px 8px;
    line-height: 10px;
}

.mob-category .cate-list {
    display: inline-block;
    font-size: 13px;
    text-align: center;
    margin-right: 15px;
}

.mob-category .categ-img {
    height: 50px;
    overflow: hidden;
}

.mob-category .cate-list img {
    display: inline-block;
    height: 50px;
}


.categorytxt {
    /* text-align: center;
    margin-top: 10px;
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre; */
    line-height: 40px;
    height: 40px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
    font-size: 13px;
    margin-bottom: unset;
    width: 80px;
  }

  @media (max-width: 575px){
    .mobile-display {
        display: none!important;
    }

    .header2{
        background-color: #FBD202;
    }
    .mobilebannerbtop {
        margin-top: 50px!important;
     }
    
 }

 .mobilebannerbtop {
    margin-top: 140px;
 }


 /* moonstyle */

 .moonmaindiv{
    position: relative;
    background: rgb(235, 235, 235);
    flex: 1 1 0%;
    min-width: 0px;
    padding-bottom: 25px;
 }

 .laQvkU {
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    padding: 10px;
}

.singlediv{
    flex: 0 0 calc(25% - 7.5px);
    padding-bottom: 0px;
    margin-top: 20px;
}

@media (max-width: 575px){
    .singlediv{
        flex: 0 0 calc(50% - 7.5px);
        padding-bottom: 0px;
        margin-top: 20px;
    }
 }



.imagediv{
    position: relative;
    background-color: transparent;
    transition: background-color 0.25s ease 0s;
    padding-bottom: 50%;
}

.ipLbIS {
    position: absolute;
    inset: 0px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}

.ipLbIS img:not(.zoomImage) {
    display: block;
    max-width: 100%;
}

.carticon{
    width: 50px;
    height: 50px;
}

.cwhUbk{
    width: 100%;
    height: 100%
}



@media (max-width: 575px){
    .carticon{
        width: 30px!important;
        height: 30px!important;
    }
    .profileicon{
        width: 30px!important;
        height: 30px!important;
    }

    .fetchlogo{
        width: 80px!important;
        height: 25px!important;
        margin-top: 4px!important;
    }

    .searchdiv{
        margin-top: unset!important
    }
    .searchbar{
        display: none!important;
    } 
    .desktop-display {
        display: block!important;
    }
    .cartTop {
        margin-top: unset!important;
    }
     
 }
 
 .carticon{
    width: 50px;
    height: 50px;
}


.profileicon{
    width: 50px;
    height: 50px;
    margin-left: 20px;
}

.fetchlogo{
    width: 180px;
    height: 50px;
}

.searchdiv{
    position: 'relative';
    margin-top: 10px
}



.mobsearchdiv {
    position: absolute;
    z-index: 1000;
    width: 100%!important;
    box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 1px 1px rgb(0 0 0 / 5%);
    -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 1px 1px rgb(0 0 0 / 5%);
    transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    right: -100%;
    height: 100%;
    top: 0;
  }
  
  
  .mobsearchdiv1 {
    position: absolute;
    z-index: 1000;
    width: 100%!important;
    box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 1px 1px rgb(0 0 0 / 5%);
    -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 1px 1px rgb(0 0 0 / 5%);
    transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    right: 0%;
    height: 100%;
    top: 0;
  }

  .searchdiv1 {
      height: 100%;
  }

  .desktop-display {
      display: none;
  }

  .cartTop {
    margin-top: 170px;
    margin-bottom: 30px;
  }

   .mobilemenu {
    position: fixed;
    top: 0;
    bottom: 0px;
    width: 80%;
    height: 100%;
    background-color: #fff;
    z-index: 1000;
    left: -100%;
    box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 1px 1px rgb(0 0 0 / 5%)!important;
    -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05)!important;
    -webkit-box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 1px 1px rgb(0 0 0 / 5%)!important;
    transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
  }

  .mobilemenu1 {
    position: fixed;
    top: 0;
    bottom: 0px;
    width: 80%;
    height: 100%;
    background-color:#fff;
    z-index: 1000;
    left: 0%;
    box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 1px 1px rgb(0 0 0 / 5%)!important;
    -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05)!important;
    -webkit-box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 1px 1px rgb(0 0 0 / 5%)!important;
    transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
  }

  .listtop {
      margin-top: 70px;
      list-style: none;
      padding-left: unset!important;
  }

  .listtop li {
    height: 65px;
    font-size: 20px;
    font-weight: 500;
    padding-left: 15px;
    position: relative;
    overflow: hidden;
    color: #979797;
  }
  .menulistic {
    color: #979797;
    margin-right: 12px;
    font-size: 22px;
  }



  /* new category */

  .luxury-experiences__grid-item {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: calc((100% - 27.8px) / 3);
    border-radius: 10px;
    box-shadow: 0 0 27px 0 rgb(0 0 0 / 15%);
    overflow: hidden;
    padding: 3px;
}

.lazy-image {
    opacity: 1;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
}

.luxury-experiences__grid-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.luxury-experiences__grid-item-title {
    text-align: center;
    font-weight: 600;
    color: #fff;
    font-size: 1.4rem;
    line-height: 1.21;
    letter-spacing: 0.84px;
    z-index: 1;
}

.luxury-experiences__grid {
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
}


    .luxury-experiences__grid {
        height: 588px;
        flex: 1;
    }


.luxury-experiences__grid-item:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.19);
    z-index: 0;
    transition: all 1s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/* .lazy-image:before {
    content: "";
    background-image: url(https://ui-assets-gc.thrillophilia.com/assets/placeholder-6a8d720….jpg);
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    background-size: cover;
    z-index: -1;
    display: block;
} */

.luxury-experiences__grid-item:nth-child(1) {
    height: 41.24%;
}

.luxury-experiences__grid-item:nth-child(2) {
    margin-top: -70px!important;
    height: 29%;
}

.luxury-experiences__grid-item:nth-child(3) {
    height: 43.53%;
}

.luxury-experiences__grid-item:nth-child(4) {
    margin-top: -127px!important;
    height: 26%;
}

.luxury-experiences__grid-item:nth-child(5) {
    margin-top: -202px!important;
    height: 38.09%;
}

.luxury-experiences__grid-item:nth-child(6) {
    margin-top: 10px!important;
    height: 44.38%;
}

.luxury-experiences__grid-item:nth-child(7) {
    margin-top: -136px!important;
    height: 44.36%;
}

.luxury-experiences__grid-item:nth-child(8) {
    margin-top: -245px!important;
    height: 26.36%;
}

.luxury-experiences__grid-item:nth-child(9) {
    margin-top: 10px!important;
    height: 26.36%;
}

.luxury-experiences__grid-item:nth-child(10) {
    margin-top: -10px!important;
    height: 30.36%;
}

.luxury-experiences__grid-item:nth-child(11) {
    margin-top: -115px!important;
    height: 48.36%;
}

.luxury-experiences__grid-item:nth-child(12) {
    margin-top: 10px!important;
    height: 26.36%;
}

@media (min-width: 1024px) {
    .luxury-experiences__grid-item:not(:nth-child(3n-2)) {
        margin-top: 18px;
    }
}

.luxury-experiences__grid-item {
    text-decoration: unset;
}

@media (max-width: 575px){
    .luxury-experiences__grid-item:nth-child(1) {
        height: 41.24%;
    }
    
    .luxury-experiences__grid-item:nth-child(2) {
        margin-top: -44px!important;
        height: 29%;
    }
    
    .luxury-experiences__grid-item:nth-child(3) {
        height: 43.53%;
    }
    
    .luxury-experiences__grid-item:nth-child(4) {
        margin-top: -67px!important;
        height: 26%;
    }
    
    .luxury-experiences__grid-item:nth-child(5) {
        margin-top: -110px!important;
        height: 38.09%;
    }
    
    .luxury-experiences__grid-item:nth-child(6) {
        margin-top: 5px!important;
        height: 44.38%;
    }
    
    .luxury-experiences__grid-item:nth-child(7) {
        margin-top: -80px!important;
        height: 40.36%;
    }
    
    .luxury-experiences__grid-item:nth-child(8) {
        margin-top: -126px!important;
        height: 26.36%;
    }
    
    .luxury-experiences__grid-item:nth-child(9) {
        margin-top: 5px!important;
        height: 26.36%;
    }
    
    .luxury-experiences__grid-item:nth-child(10) {
        margin-top: -30px!important;
        height: 26.36%;
    }
    
    .luxury-experiences__grid-item:nth-child(11) {
        margin-top: -75px!important;
        height: 40.36%;
    }
    
    .luxury-experiences__grid-item:nth-child(12) {
        margin-top: 5px!important;
        height: 26.36%;
    }

    .luxury-experiences__grid-item-title{
        font-size: 14px!important;
    }

    .luxury-experiences__grid {
        height: 334px;
        /* margin-left: 18px; */
        flex: 1 1;
    }

    .gridadjust {
        margin-top: 177px!important;
    }

    .luxury-experiences__grid-item {
        width: calc((100% - 11.8px) / 3);
    }
 }

 .gridadjust {
     margin-top: 350px;
 }

 .img-hover-zoom img {
    transition: transform 2s, filter 1.5s ease-in-out;
    transform-origin: center center;
    filter: brightness(100%);
  }
  
  /* The Transformation */
  .img-hover-zoom:hover img {
    filter: brightness(50%);
    transform: scale(1.1);
  }

  .detailsMargintop{
      margin-top: 170px!important;
  }

  @media (max-width: 575px){
    .detailsMargintop{
        margin-top: 0px!important;
    }
  }

  .newarrival-img-border{
    border: 1px solid #d3d3d3;
    padding: 5px;
    height: 300px
  }


